"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _vue = require("vue");

var _default = (0, _vue.defineComponent)({
  name: 'App'
});

exports.default = _default;