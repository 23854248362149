"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.render = render;

var _vue = require("vue");

function render(_ctx, _cache) {
  const _component_router_view = (0, _vue.resolveComponent)("router-view");

  return (0, _vue.openBlock)(), (0, _vue.createBlock)(_component_router_view);
}